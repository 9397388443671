/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  $.fn.randomize = function(selector){
    var $elems = selector ? $(this).find(selector) : $(this).children(),
        $parents = $elems.parent();

    $parents.each(function(){
      $(this).children(selector).sort(function(){
        return Math.round(Math.random()) - 0.5;
      }).detach().appendTo(this);
    });

    return this;
  };


  $('.testimonials').randomize('.testimonial');


  $('.testimonials').slick({
    autoplay: true,
    autoplaySpeed: 6500, 
    arrows: false,
    slide: 'div'
  });


  $('.testimonial').fadeIn(600, function() {
    $('.testimonial p').slideDown('slow', function() {
      $('.testimonial__author').slideDown('slow');
    });
  });


  addClassToSidebar();

  $(window).resize(function() {
    addClassToSidebar();
  });

  function addClassToSidebar() {
    if ( $(window).height() < 900) {
      $('.sidebar').addClass('beAbsolute').removeClass('beFixed');
    } else {
      $('.sidebar').addClass('beFixed').removeClass('beAbsolute');
    }
  }

  $.textMetrics = function(el) {

    var tm = document.createElement('span'),
        $tm = $(tm);
    $tm.css({
      border: 0,
      padding: 0,
      position: 'absolute',
      visibility: 'hidden'
    });

    tm.appendChild(
        document.createTextNode(el.textContent || el.innerText)
    );

    el.appendChild(tm);
    var rect = tm.getClientRects()[0];
    $tm.remove();

    return {
      height: rect.bottom - rect.top,
      width: rect.right - rect.left
    };
  };

  // custom implementation
  $(function(){
    $('h1.text').each(function(){
      var widths = [], maxwidth = 0, width = 0;
      $(this).children('span').each(function(){
        width = $.textMetrics(this)['width'];
        widths.push({el: this, width: width});
        if(maxwidth < width) maxwidth = width;
      });
      widths.forEach(function(w,i){
        $(w.el).css({
          'font-size': (w.width > 0 ? maxwidth / w.width : 0).toFixed(5) + 'em'
        });
      });
    });
  });



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
